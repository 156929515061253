import moment from "moment";

export const dateFormat = (date) => {
  if (date && date.split(' ').join('') !== '' && date.split(' ').join('') !== '0') {
    // 日付が "YY MMDD" 形式の場合に補完する
    const trimmedDate = date.replace(/\s/g, ''); // 空白を取り除く
    let formattedDate;
    
    // "YYMMDD"形式の場合
    if (/^\d{6}$/.test(trimmedDate)) {
      const year = '20' + trimmedDate.slice(0, 2); // 最初の2桁は西暦の年（20XX年）
      const month = trimmedDate.slice(2, 4); // 次の2桁は月
      const day = trimmedDate.slice(4, 6); // 最後の2桁は日
      formattedDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    } else {
      // それ以外の形式の場合はそのまま moment に渡す
      formattedDate = moment(date);
    }

    if (formattedDate.isValid()) {
      return formattedDate.format('YYYY/MM/DD');
    } else {
      return ""; // 無効な日付の場合、空文字列を返す
    }
  } else {
    return "";
  }
};



export const dateandtimeFormat = (date) => {
  if (date && date.split(' ').join('') !== '' && date.split(' ').join('') !== '0') {
    date = moment(date, 'YYYYMMDDHHmmss').format('YYYY/MM/DD HH:mm:ss');
    return date;
  } else {
    return "";
  }
};
